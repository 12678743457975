import React from 'react';

import { useIntl } from 'react-intl';

import Translation from 'components/Content/Translation/Translation';
import { SymposiumLevels } from 'store/features/mainSliceTypes';

type SubscriptionTitleProps = {
  level: SymposiumLevels;
};
const SubscriptionTitle = ({ level }: SubscriptionTitleProps) => {
  const intl = useIntl();
  switch (level) {
    case 'GUIDE':
    case 'PHISH':
    case 'RESPOND':
      return (
        <Translation
          id={`common.subscription.header.${level.toLocaleLowerCase()}`}
          values={{
            demo: (
              <b>{intl.formatMessage({ id: 'common.demo' }).toUpperCase()}</b>
            ),
          }}
        />
      );
    case 'GUIDE+':
    case 'PHISH+':
    case 'RESPOND+':
      // get product name without the + to get the correct label name
      const productLevel = level.substring(0, level.length - 1);
      return (
        <Translation
          id={`common.subscription.header.${productLevel.toLocaleLowerCase()}.plus`}
          values={{
            demo: (
              <b>{intl.formatMessage({ id: 'common.demo' }).toUpperCase()}</b>
            ),
          }}
        />
      );
    default:
      return (
        <Translation
          id="common.subscription.header.bolt.on"
          values={{
            demo: (
              <b>{intl.formatMessage({ id: 'common.demo' }).toUpperCase()}</b>
            ),
            boltOn: level.toUpperCase(),
          }}
        />
      );
  }
};

export default SubscriptionTitle;
