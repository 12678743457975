import React, { ReactElement, useMemo } from 'react';

import { SkeletonIcon, SkeletonText } from '@carbon/react';

import { LoadingTextContainer } from '../SideNav.styles';

const LoadingSideNav = () => {
  const loadingItems = useMemo(() => {
    let loadingComponents: ReactElement[] = [];
    for (let i = 0; i < 5; i++) {
      loadingComponents.push(
        <div
          key={`loading-side-nav-${i}`}
          data-testid="loading-side-nav"
          className="cds--side-nav__item"
        >
          <div className="cds--side-nav__submenu">
            <div className="cds--side-nav__icon cds--side-nav__icon--small">
              <SkeletonIcon />
            </div>
            <LoadingTextContainer className="cds--side-nav__submenu-title">
              <SkeletonText width="100%" />
            </LoadingTextContainer>
          </div>
        </div>,
      );
    }
    return loadingComponents;
  }, []);

  return <>{loadingItems}</>;
};

export default LoadingSideNav;
