import { useState } from 'react';

import { breakpoints } from '../breakpoints';

import useDeepCompareEffect from './useDeepCompareEffect';
import useWindowSize from './useWindowSize';

export type BreakpointKey = keyof typeof breakpoints;

/**
 * useBreakpoints: Hook for getting the current active breakpoints.
 *
 * @param breakpointKeys - The breakpoint keys to use for the hook.
 * @returns - The current breakpoint key and the current breakpoint value.
 */
const useBreakpoints = <T extends BreakpointKey>(...breakpointKeys: T[]) => {
  const { width } = useWindowSize();
  const [activeBreakpoints, setActiveBreakpoints] = useState(
    Object.fromEntries(
      breakpointKeys.map((key) => [key, width && width < breakpoints[key]]),
    ) as Record<T, boolean>,
  );

  useDeepCompareEffect(() => {
    setActiveBreakpoints(
      Object.fromEntries(
        breakpointKeys.map((key) => [key, width && width < breakpoints[key]]),
      ) as Record<T, boolean>,
    );
  }, [breakpointKeys, width]);

  return activeBreakpoints;
};

export default useBreakpoints;
