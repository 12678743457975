import { Header } from '@carbon/react';
import styled, { css } from 'styled-components';

import {
  MAX_CONTAINER_WIDTH,
  MAX_CONTENT_CONTAINER_WIDTH,
  breakpoint,
} from 'theme';

import {
  DELEGATED_ACCESS_HEADER_HEIGHT,
  DELEGATED_ACCESS_HEADER_HEIGHT_VALUE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_VALUE,
} from './Header/Header.styles';

const SIDEBAR_WIDTH = '16rem';
const SIDEBAR_ICON_WIDTH = '3rem';

export const Content = styled.main<{
  narrowWidth?: boolean;
  noHeader?: boolean;
  $bottomHeight?: number;
  $hasDelegatedAccess?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) =>
    props.$hasDelegatedAccess
      ? props.noHeader
        ? '5rem'
        : '10rem'
      : props.noHeader
        ? '2rem'
        : '7rem'};

  padding-left: 2em;
  padding-right: 2em;
  min-height: 100vh;

  width: 100%;
  margin: 0 auto;
  max-width: calc(${(props) =>
    props.narrowWidth ? MAX_CONTENT_CONTAINER_WIDTH : MAX_CONTAINER_WIDTH} +
  32px);

  ${({ $bottomHeight }) =>
    $bottomHeight &&
    css`
      padding-bottom: 5rem;
    `}
}

${breakpoint('mobile')} {
  padding-top: 6rem;
  padding-left: 1em;
  padding-right: 1em;
}
`;

export const CustomHeader = styled(Header)<{ $delegatedAccess?: boolean }>`
  && {
    background-color: ${({ theme }) => theme.componentColors.headerBg};
    top: ${({ $delegatedAccess }) =>
      $delegatedAccess ? DELEGATED_ACCESS_HEADER_HEIGHT : 0};
    border-bottom: none;
    padding: 0px 12px;
    z-index: 10;
    border-bottom: 1px solid var(--cds-layer-accent-01);
    height: ${HEADER_HEIGHT};
  }
`;

export const Wrapper = styled.div<{
  $isTransparent?: boolean;
  $hasDelegatedAccess?: boolean;
}>`
  background: ${({ theme }) =>
    theme.name === 'dark'
      ? theme.componentColors.bodyBg
      : theme.colorPalette.grey[50]};
  background-color: ${(props) => props.$isTransparent && 'unset'};

  .cds--side-nav__overlay {
    top: ${({ $hasDelegatedAccess }) =>
      $hasDelegatedAccess
        ? `${HEADER_HEIGHT_VALUE + DELEGATED_ACCESS_HEADER_HEIGHT_VALUE}px`
        : HEADER_HEIGHT};
  }
`;

export const ContentWrapper = styled.div<{
  $isAdmin: boolean;
  $withSideMenu: boolean;
  $direction: DirectionType;
  $iconMenuExpanded: boolean;
  $useIconSideNav: boolean;
}>`
  ${({
    $isAdmin,
    $withSideMenu,
    $direction,
    $iconMenuExpanded,
    $useIconSideNav,
  }) => {
    if (!(!$isAdmin || !$withSideMenu)) {
      return `@media only screen and (min-width: 66rem) {
        ${$direction === 'rtl' ? `margin-right: ${$iconMenuExpanded || !$useIconSideNav ? SIDEBAR_WIDTH : SIDEBAR_ICON_WIDTH};` : `margin-left: ${$iconMenuExpanded || !$useIconSideNav ? SIDEBAR_WIDTH : SIDEBAR_ICON_WIDTH};`}
        transition: ${$direction === 'rtl' ? 'margin-right' : 'margin-left'} .11s cubic-bezier(0.2, 0, 1, 0.9);
        will-change: ${$direction === 'rtl' ? 'margin-right' : 'margin-left'};
      }`;
    }
  }}
`;
