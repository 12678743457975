import { useEffect, useState } from 'react';

interface WindowSize {
  width?: number;
  height?: number;
}

/**
 * useWindowSize: Hook for getting the current window size as a object.
 * This hook uses an event listener on the resize event to update the state.
 * @returns {
 *    width: number,
 *    height: number
 *  }
 */
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
