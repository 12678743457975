import React from 'react';

import { Modal as CarbonModal, ModalProps } from '@carbon/react';
import ReactDOM from 'react-dom';

export type { ModalProps };

const Modal = (props: ModalProps) =>
  ReactDOM.createPortal(
    <CarbonModal {...props}>{props.children}</CarbonModal>,
    document.getElementById('react-root') || document.body,
  );

export default Modal;
