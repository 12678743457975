import React, { useEffect } from 'react';
// Polyfills
import 'react-app-polyfill/ie9'; // This is required for IE 11 fetch polyfill

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/array/to-sorted.js';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/array';
import 'core-js/es/string';
import 'core-js/es/object';

import 'raf/polyfill';
import 'classlist-polyfill';
import 'array-includes';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Node.prototype.children';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Element.prototype.matches';
import 'intl';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store, { persistor } from './store/createStore';

declare global {
  interface Window {
    __data__?: any;
  }
}

const serverData = window.__data__ || {};

if (serverData.config.sentry) {
  Sentry.init({
    dsn: serverData.config.sentry,
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: ['cybsafe-frontend'],

        // Defines how to handle errors that contain third party stack frames.
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration({
        mask: ['.masked-data'],
        block: ['.cds--password-input'],
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release: serverData.config.release,
    ignoreErrors: [
      'Network Error',
      'Not enough storage is available to complete this operation.',
      'Object Not Found Matching Id',
      'ResizeObserver loop limit exceeded',
      'Failed to fetch dynamically imported module',
      'ResizeObserver loop completed with undelivered notifications',
      'BadRequestError',
      'NetworkError',
      'NotFoundError',
      'OfflineError',
      'Failed to fetch',
      'network error',
      'cancelled',
      'Load failed',
    ],
    normalizeDepth: 10,
    beforeSend(event) {
      let firstFilename =
        event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename;
      const functionCheck =
        event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.function;
      if (functionCheck === 'sentryWrapped') {
        firstFilename =
          event.exception?.values?.[0]?.stacktrace?.frames?.[1]?.filename;
      }
      // Ignore errors from third party scripts such as onetrust
      // Check if firstFileName is not null and type of string
      if (firstFilename && typeof firstFilename === 'string') {
        if (
          firstFilename.includes('onetrust.com') ||
          firstFilename.includes('otSDKStub') ||
          firstFilename.includes('otBannerSdk')
        ) {
          return null;
        }
      }
      return event;
    },

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

const initApp = (App: any) => {
  const container = document.getElementById('react-root');
  const root = createRoot(container!);
  root.render(
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>,
  );
};

initApp(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./App').default;
    initApp(NextApp);
  });
}

/*
  Disable React Intl error messages as they clutter the console,
  translations always come after development so they are expected,
  and we can see missing messages anyway as the label is displayed.
 */
// eslint-disable-next-line
const consoleError = console.error.bind(console);

// eslint-disable-next-line no-console
console.error = (message: string, ...args: any) => {
  if (
    typeof message === 'string' &&
    (message.startsWith('[React Intl] Missing message:') ||
      message.startsWith('[React Intl] Cannot format message:') ||
      message.includes(
        'Failed prop type: Invalid prop `title` of type `object` supplied to `SideNavMenu`',
      ))
  ) {
    return;
  }

  consoleError(message, ...args);
};
