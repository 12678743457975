import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

import isEqual from 'lodash/isEqual';

const useConstantDep = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

/**
 * useDeepCompareEffect: Used to compare the previous and current values of a
 * value and only run the callback if the values have changed.
 * @param callback - The callback to run on every render.
 * @param dependencies - The dependencies to use for the callback.
 */
const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: DependencyList,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useConstantDep));
};

export default useDeepCompareEffect;
