import React from 'react';

import {
  Dropdown as CarbonDropdown,
  DropdownProps as CarbonDropdownProps,
} from '@carbon/react';

import { Item } from '../Dropdowns.types';
import DropdownSkeleton from '../DropdownSkeleton';

type DropdownProps = CarbonDropdownProps<Item> & {
  loading?: boolean;
};

const Dropdown = ({ loading, ...rest }: DropdownProps) =>
  loading ? <DropdownSkeleton /> : <CarbonDropdown {...rest} />;

export type { DropdownProps };

export default Dropdown;
