import React from 'react';

import { AsleepFilled, Awake } from '@carbon/icons-react';

import Translation from 'components/Content/Translation/Translation';
import { useAppDispatch, useAppSelector } from 'store/createStore';
import { selectThemeType, setTheme } from 'store/features/themeSlice';
import { usePrevious } from 'utils/hooks';

import { IconIncoming, IconOutgoing } from './ThemeToggle.styles';

const getIcon = (themeType: 'light' | 'dark') => {
  switch (themeType) {
    case 'light':
      return <AsleepFilled data-testid="dark-mode-icon" />;
    case 'dark':
      return <Awake data-testid="light-mode-icon" />;
    default:
      return <></>;
  }
};

const ThemeToggle = () => {
  const dispatch = useAppDispatch();
  const themeType = useAppSelector(selectThemeType);
  const previousThemeType = usePrevious(themeType);
  return (
    <div className="cds--side-nav__item">
      <button
        id="theme-toggle-side-nav-btn"
        className="cds--side-nav__submenu"
        onClick={() =>
          dispatch(setTheme(themeType === 'light' ? 'dark' : 'light'))
        }
      >
        <div className="cds--side-nav__icon cds--side-nav__icon--small">
          <IconIncoming key={themeType + 'incoming'}>
            {getIcon(themeType)}
          </IconIncoming>
          <IconOutgoing key={previousThemeType + 'outgoing'} aria-hidden="true">
            {getIcon(previousThemeType)}
          </IconOutgoing>
        </div>
        <span className="cds--side-nav__submenu-title">
          <Translation id="common.button.switchTheme" />
        </span>
      </button>
    </div>
  );
};

export default ThemeToggle;
