import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useIntl } from 'react-intl';

import { ToastNotification } from 'components/Notifications';
import { useHasDelegatedAccessEnabled } from 'utils/hooks';

import { StyledToastWrapper } from './ToastContainer.styles';
import { Toast } from './ToastProvider';

interface ToastContainerProps {
  toasts: Toast[];
  removeToast: (id: number) => void;
}

const MAX_TOASTS = 3;
const DEFAULT_TOAST_DURATION = 5000;

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  removeToast,
}) => {
  const delegatedAccess = useHasDelegatedAccessEnabled();

  const intl = useIntl();
  const orderedToasts = [...toasts].reverse().slice(0, MAX_TOASTS);

  const getNotificationTitleFromType = (type: Toast['type']) => {
    switch (type) {
      case 'success':
        return intl.formatMessage({ id: 'common.success' });
      case 'info':
        return intl.formatMessage({ id: 'common.info' });
      case 'warning':
        return intl.formatMessage({ id: 'common.warning' });
      case 'error':
        return intl.formatMessage({ id: 'common.error' });
    }
  };

  if (orderedToasts.length === 0) {
    return null;
  }

  return (
    <StyledToastWrapper $hasDelegatedAccess={delegatedAccess ?? false}>
      <div className="toast-container">
        <AnimatePresence>
          {orderedToasts.map((toast, index) => (
            <motion.div
              key={toast.id}
              layout
              initial={{
                opacity: 0,
                x: 100,
                y: 0,
              }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100, transition: { duration: 0 } }}
              transition={{ duration: 0.5, type: 'spring' }}
              onAnimationComplete={() => {
                if (index === 0) {
                  setTimeout(
                    () => removeToast(toast.id),
                    DEFAULT_TOAST_DURATION,
                  );
                }
              }}
            >
              <ToastNotification
                kind={toast.type}
                title={getNotificationTitleFromType(toast.type)}
                caption={toast.message}
                className="toast"
                onCloseButtonClick={() => removeToast(toast.id)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </StyledToastWrapper>
  );
};

export default ToastContainer;
