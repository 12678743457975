import { AnyAction, Store } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import qs, { ParsedQs } from 'qs';
import { ApiError, getJSON } from 'redux-api-middleware';

import { RootState } from 'store/createStore';
import { selectLocale } from 'store/features/configSlice';
import { selectClientId } from 'store/features/mainSlice';
import { getCookie, setCookie } from 'utils/token';

import ApiRequestCache from './ApiRequestCache';
import { baseHeaders } from './utils';

const __DEV__ = process.env.NODE_ENV !== 'production';

interface EndpointQueryParams extends ParsedQs {
  locale?: string;
  client_id?: string;
}

export type Options = {
  cache?: boolean;
  dontAppendSlash?: boolean;
  dontAppendClientId?: boolean;
  dontAppendLocale?: boolean;
  reduxAction?: (payload: unknown) => AnyAction;
  withoutTokenHeader?: boolean;
  additionalHeaders?: Record<string, string>;
  rawResponse?: boolean;
};

type QueueItem = {
  cacheKey: string;
  endpoint: string;
  options: Options;
  resolve: (value: unknown) => void;
  reject: (error: unknown) => void;
};

function createRequestConfig(
  state: RootState,
  method = 'get',
  options: Options = {},
): RequestInit {
  let tokenHeader: Record<string, string> = {};

  if (!options.withoutTokenHeader) {
    const cookie = getCookie();
    tokenHeader = cookie
      ? {
          Authorization: `Bearer ${cookie}`,
        }
      : {};
  }

  const request: RequestInit = {
    method,
    headers: {
      ...baseHeaders(state),
      ...tokenHeader,
      ...options.additionalHeaders,
    },
    credentials: 'same-origin',
  };

  return request;
}

function validateEndpoint(endpoint: string) {
  if (endpoint.indexOf('/') !== 0) {
    throw new Error(`Endpoint url does not start with \`/\`: ${endpoint}`);
  }
}

function processEndpoint(
  state: RootState,
  endpoint: string,
  options: Options,
): string {
  const queryIndex = endpoint.lastIndexOf('?');
  const hasQueryParams = queryIndex !== -1;

  let queryParams: EndpointQueryParams = {};
  if (hasQueryParams) {
    queryParams = qs.parse(endpoint.slice(queryIndex + 1));
  }

  if (!options?.dontAppendLocale && !queryParams['locale']) {
    queryParams['locale'] = selectLocale(state);
  }

  if (!options?.dontAppendClientId && !queryParams['client_id']) {
    queryParams['client_id'] = selectClientId(state)?.toString();
  }

  let uri = hasQueryParams ? endpoint.slice(0, queryIndex) : endpoint;

  if (!options?.dontAppendSlash && uri.substr(-1) !== '/') {
    uri += '/';
  }

  //if options.dontAppendSlash is true, then uri will not have a trailing slash
  if (
    options?.dontAppendSlash &&
    uri.charAt(uri.length - 1) === '/' &&
    uri.length > 1
  ) {
    uri = uri.slice(0, -1);
  }

  return Object.keys(queryParams).length > 0
    ? `${uri}?${qs.stringify(queryParams, { indices: false })}`
    : uri;
}

async function getNewAccessToken(state: any) {
  try {
    const refreshToken = getCookie('refreshToken');
    if (refreshToken) {
      const body = JSON.stringify({ refreshToken });
      const options = createRequestConfig(state, 'POST', {
        withoutTokenHeader: true,
      });
      const accessToken = await fetch('/api/v1/auth/token/r1/', {
        ...options,
        body,
      });
      const json = await getJSON(accessToken);
      if (json) {
        setCookie(json);
        return;
      }
    } else {
      window.location.replace('/login');
    }
  } catch {
    window.location.replace('/login');
  }
  return;
}

export default class ApiData {
  _store: Store;
  _promiseCache: Map<string, Promise<any>>;
  _enableLogging: boolean;
  _requestCache: ApiRequestCache;
  _queue: QueueItem[];

  constructor(store: Store, enableLogging = true) {
    this._store = store;
    this._promiseCache = new Map();
    this._queue = [];
    this._requestCache = new ApiRequestCache();
    this._enableLogging = enableLogging;
  }

  getData(endpoint: string, options: Options = {}) {
    // Validate and process endpoint
    if (__DEV__) {
      validateEndpoint(endpoint);
    }

    const reduxState = this._store.getState();
    endpoint = processEndpoint(reduxState, endpoint, options);

    const cacheKey = endpoint;

    // Check cache
    if (options.cache) {
      const maybeResponse = this._requestCache.get(cacheKey);
      if (maybeResponse) {
        return Promise.resolve(maybeResponse);
      }
    }

    // Batch requests
    // Heavily inspired by dataloader.js

    const cachedPromise = this._promiseCache.get(cacheKey);
    if (cachedPromise) {
      return cachedPromise;
    }

    const promise = new Promise((resolve, reject) => {
      this._queue.push({
        cacheKey,
        endpoint,
        options,
        resolve,
        reject,
      });

      // Determine if a dispatch of this queue should be scheduled.
      // A single dispatch should be scheduled per queue at the time when the
      // queue changes from "empty" to "full".
      if (this._queue.length === 1) {
        enqueuePostPromiseJob(() => this.dispatchQueue());
      }
    });

    this._promiseCache.set(cacheKey, promise);
    return promise;
  }

  async fetch(
    method: string,
    endpoint: string,
    params = {},
    options: Options = {},
  ) {
    // Validate and process endpoint
    if (__DEV__) {
      validateEndpoint(endpoint);
    }

    const reduxState = this._store.getState();
    endpoint = processEndpoint(reduxState, endpoint, options);
    const requestConfig = createRequestConfig(reduxState, method, options);
    const body = params ? JSON.stringify(params) : '';
    if (method.toLowerCase() !== 'get') {
      requestConfig.body = body;
    }

    const response = await fetch(endpoint, requestConfig);
    let returnValue = await this._processResponse(
      response,
      options,
      endpoint,
      requestConfig,
    );

    if (returnValue === 'RETRY_FETCH') {
      returnValue = await this.fetch(method, endpoint, params, options);
    }

    return returnValue;
  }

  dispatchQueue() {
    const queue = this._queue;
    this._queue = [];

    if (__DEV__ && this._enableLogging) {
      /* eslint-disable no-console */
      console.groupCollapsed(
        `Fetching ${queue.length} api request${queue.length === 1 ? '' : 's'}`,
      );
      queue.forEach(({ endpoint }) => {
        console.log(`Endpoint: \`${endpoint}\``);
      });
      console.groupEnd();
      /* eslint-enable no-console */
    }

    queue.forEach(({ resolve, reject, ...job }) => {
      this._processJob(job).then(resolve).catch(reject);
    });

    // Clear promise cache
    this._promiseCache.clear();
  }

  async _processJob({
    cacheKey,
    endpoint,
    options,
  }: {
    cacheKey: string;
    endpoint: string;
    options: Options;
  }) {
    const reduxState = this._store.getState();
    const requestConfig = createRequestConfig(reduxState);

    const response = await fetch(endpoint, requestConfig);

    const returnValue = await this._processResponse(
      response,
      options,
      endpoint,
      requestConfig,
    );

    if (returnValue !== null) {
      this._requestCache.set(cacheKey, returnValue);
    }

    return returnValue;
  }

  async _processResponse(
    response: Response,
    options: Options,
    endpoint: string,
    requestConfig: RequestInit,
  ) {
    if (!response.ok) {
      // If accessToken expired, GET another one and redo the api call
      if (response.status === 401) {
        await getNewAccessToken(this._store.getState());
        return Promise.resolve('RETRY_FETCH');
      }

      const json = await getJSON(response);
      const requestId = response.headers.get('CybSafe-Request-ID');
      const requestInfo = {
        endpoint,
        method: requestConfig.method,
        body: requestConfig.body,
      };
      let error;
      if (json) {
        json.requestId = requestId;
        json.requestInfo = requestInfo;
        error = new ApiError(response.status, response.statusText, json);
      } else {
        error = new ApiError(response.status, response.statusText, {
          requestId,
          requestInfo,
        });
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    }

    const processedResponse = options.rawResponse
      ? await response
      : await getJSON(response);
    if (processedResponse) {
      if (options.reduxAction) {
        this._store.dispatch(options.reduxAction(processedResponse));
      }

      return processedResponse;
    }
    return null;
  }
}

const enqueuePostPromiseJob = function (fn: () => void) {
  Promise.resolve().then(() => fn());
};
