import React from 'react';

import { useGetSymposiumColours } from 'components/Subscription/getColors';
import { SymposiumLevels } from 'store/features/mainSliceTypes';

import useHasDelegatedAccessEnabeld from '../../../../../utils/hooks/useHasDelegatedAccessEnabled';
import SubscriptionTitle from '../SubscriptionTitle/SubscriptionTitle';

import { DemoContainer } from './SubscriptionBanner.styles';

type DemoBannerProps = {
  level: SymposiumLevels;
};

export const SubscriptionBanner = ({ level }: DemoBannerProps) => {
  const hasDelegatedAccessEnabled = useHasDelegatedAccessEnabeld();
  const symposiumColours = useGetSymposiumColours(level);
  return (
    <DemoContainer
      {...symposiumColours}
      $hasDelegatedAccessBanner={hasDelegatedAccessEnabled}
    >
      <SubscriptionTitle level={level} />
    </DemoContainer>
  );
};

export default SubscriptionBanner;
