import styled from 'styled-components';

import { HEADER_HEIGHT } from 'components/Layout/Header/Header.styles';
import { DemoContainerProps } from 'components/Subscription/Subscription.types';

export const DemoContainer = styled.div<
  DemoContainerProps & { $hasDelegatedAccessBanner: boolean }
>`
  background: linear-gradient(
    90deg,
    ${(props) => props.startColor} 0%,
    ${(props) => props.midColor} 50%,
    ${(props) => props.endColor} 100%
  );

  ${({ $hasDelegatedAccessBanner }) =>
    $hasDelegatedAccessBanner && 'margin-top: 3rem;'}
  color: white;
  padding: 1rem;
  position: fixed;
  z-index: 5999;
  top: ${HEADER_HEIGHT};
  width: 100%;
`;
