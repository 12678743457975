import React from 'react';

import { useTheme } from 'styled-components';

import { FlexBox } from 'components/Containers';
import Translation from 'components/Content/Translation/Translation';

import { CybSafeLogo, PoweredByCybSafeContainer } from '../SideNav.styles';

const PoweredByCybSafe = () => {
  const theme = useTheme();
  return (
    <div data-testid="powered-by-cybsafe" className="cds--side-nav__item">
      <PoweredByCybSafeContainer className="cds--side-nav__submenu">
        {/* Fake Icon so component is not partially displayed when the icon menu is not expanded */}
        <div className="cds--side-nav__icon cds--side-nav__icon--small" />
        <FlexBox gap="4px" className="cds--side-nav__submenu-title">
          <Translation id="partner_poweredby" />
          <CybSafeLogo
            src={
              theme.name === 'light'
                ? 'https://cybsafe-resources.s3.eu-west-1.amazonaws.com/brand/CYBSAFE-logo-black.svg'
                : 'https://s3-eu-west-1.amazonaws.com/cybsafe-resources/cybsafe_logo_white-01.svg'
            }
            alt="CybSafe"
          />
        </FlexBox>
      </PoweredByCybSafeContainer>
    </div>
  );
};

export default PoweredByCybSafe;
