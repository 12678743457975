import styled, { keyframes } from 'styled-components';

const themeIncomingAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: translateY(-14px) scale(0);
  }
  75% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const themeOutgoingAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-4px) scale(0.8);
  }
  100% {
    transform: translateY(12px) scale(0);
  }
`;

export const IconIncoming = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  animation-name: ${themeIncomingAnimation};
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
`;

export const IconOutgoing = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  transform: scale(0);
  animation-name: ${themeOutgoingAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
`;
