import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import FeatureFlagged from 'components/FeatureFlag/FeatureFlagged';
import Layout from 'components/Layout/Layout';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import { FeatureFlagsContext, useFeatureFlag } from 'feature-flags';
import { useAppSelector } from 'store/createStore';
import { selectUserInfo } from 'store/features/mainSlice';
import { SettingsStateInterface } from 'store/features/settingsSlice';
import { retry } from 'utils';

import { FeatureFlagsInterface } from '../store/features/mainSliceTypes';

import AltPathNavigate from './components/AltPathNavigate';
import RouteWithContext from './components/RouteWithContext';

const AddExistingGroups = lazy(() =>
  retry(() => import('pages/Settings/GroupManagement/AddExistingGroups')),
);

const ViewUserSegment = lazy(() =>
  retry(() => import('pages/Admin/Segments/Detail/SegmentDetail')),
);
const UserSegmentsOverview = lazy(() =>
  retry(() => import('pages/Admin/Segments/Overview/SegmentOverview')),
);
const UsbPageDownload = lazy(() =>
  retry(() => import('pages/Usb/UsbPageDownload')),
);
const OnDemandHelp = lazy(() =>
  retry(() => import('pages/Learning/OnDemandHelp/OnDemandHelp')),
);
const OnDemandHelpTopics = lazy(() =>
  retry(() => import('pages/Learning/OnDemandHelp/Topics/Topics')),
);
const OnDemandHelpAdvice = lazy(() =>
  retry(() => import('pages/Learning/OnDemandHelp/Advice/Advice')),
);
const Goals = lazy(() => retry(() => import('pages/Goals/Overview/Overview')));
const FeedBackQuestions = lazy(() =>
  retry(() => import('pages/FeedbackQuestions/FeedBackQuestion')),
);
const UserReport = lazy(() =>
  retry(() => import('pages/Reports/UserReport/UserReport')),
);
const Certificates = lazy(() =>
  retry(() => import('pages/Reports/Certificates/Certificates')),
);
const TemporarySentimentQuestion = lazy(() =>
  retry(
    () => import('pages/Quizzes/Module/components/TemporarySentimentQuestion'),
  ),
);
const PassphraseReport = lazy(() =>
  retry(() => import('pages/Reports/Passphrases/PassphraseReport')),
);
const BehaviourChange = lazy(() =>
  retry(() => import('pages/Reports/BehaviourChange/BehaviourChangePage')),
);
const AdvocacyReport = lazy(() =>
  retry(() => import('pages/Reports/Advocacy/Advocacy')),
);
const SecurityHeroes = lazy(() =>
  retry(() => import('pages/Reports/SecurityHeroes/SecurityHeroes')),
);
const SecurityHeroesUserBreakdown = lazy(() =>
  retry(
    () => import('pages/Reports/SecurityHeroes/SecurityHeroesUsersBreakdown'),
  ),
);
const EngagementReport = lazy(() =>
  retry(() => import('pages/Reports/Engagement/Engagement')),
);
const EngagementActivities = lazy(() =>
  retry(() => import('pages/Reports/Engagement/Activities')),
);

const CreateActivity = lazy(() =>
  retry(() => import('pages/Reports/Engagement/CreateActivity')),
);
const ActivityAttendanceReport = lazy(() =>
  retry(() => import('pages/Reports/Engagement/ActivityAttendance')),
);
const Annotations = lazy(() =>
  retry(() => import('pages/Admin/Annotations/Annotations')),
);

const AnnotationsOverview = lazy(() =>
  retry(
    () =>
      import('pages/Admin/Annotations/AnnotationsOverview/AnnotationsOverview'),
  ),
);
const CSVReport = lazy(() =>
  retry(() => import('pages/Admin/CSVReport/CSVReport')),
);
const InsightsAdmin = lazy(() =>
  retry(() => import('pages/Admin/Insights/InsightsAdmin')),
);
const GroupManagement = lazy(() =>
  retry(() => import('pages/Settings/GroupManagement/GroupManagement')),
);
const EditProfile = lazy(() =>
  retry(() => import('pages/Settings/EditProfile/EditProfile')),
);
const CSVUploadManager = lazy(() =>
  retry(() => import('pages/Settings/CSVupload/CSVUploadManager')),
);
const CSVUploadManagerV2 = lazy(() =>
  retry(() => import('pages/Settings/CSVupload/CSVUploadManagerV2')),
);
const RiskRoomsSettings = lazy(() =>
  retry(() => import('pages/Settings/RiskRooms/RiskRooms')),
);
const AddNewUser = lazy(() =>
  retry(() => import('pages/Settings/AddNewUser/AddNewUser')),
);
const AccountOverview = lazy(() =>
  retry(() => import('pages/Settings/AccountOverview/AccountOverview')),
);
const IdentityManagement = lazy(() =>
  retry(() => import('pages/Settings/IdentityManagement/IdentityManagement')),
);
const IdentityManagementV2 = lazy(() =>
  retry(() => import('pages/Settings/IdentityManagement/IdentityManagementV2')),
);
const IdentityManagementCreateForm = lazy(() =>
  retry(
    () =>
      import('pages/Settings/IdentityManagement/IdentityManagementCreateForm'),
  ),
);
const IdentityManagementEditForm = lazy(() =>
  retry(
    () =>
      import('pages/Settings/IdentityManagement/IdentityManagementEditForm'),
  ),
);
const UserProvisioning = lazy(() =>
  retry(() => import('pages/Settings/IdentityManagement/UserProvisioning')),
);
const AwarenessTools = lazy(() =>
  retry(() => import('pages/Settings/AwarenessTools/AwarenessToolsPage')),
);
const MSPToolkit = lazy(() =>
  retry(() => import('pages/Settings/MPSToolkit/MSPToolkit')),
);
const FeaturedModules = lazy(() =>
  retry(() => import('pages/Settings/FeaturedModules/FeaturedModules')),
);
const CultureSettings = lazy(() =>
  retry(() => import('pages/Settings/Culture/CultureSettings')),
);
const EmailActivity = lazy(() =>
  retry(() => import('pages/Reports/Engagement/EmailActivity')),
);
const AuditActivity = lazy(() =>
  retry(() => import('pages/Settings/AuditActivity/AuditActivity')),
);
const PhishingActivity = lazy(() =>
  retry(() => import('pages/Settings/PhishingActivity/PhishingActivity')),
);
const Learning = lazy(() =>
  retry(() => import('pages/Settings/Learning/Learning')),
);
const News = lazy(() => retry(() => import('pages/News/News')));
const RefresherTest = lazy(() =>
  retry(() => import('pages/Quizzes/RefresherTest/RefresherTest')),
);
const Proactivity = lazy(() =>
  retry(() => import('pages/Reports/Proactivity/Proactivity')),
);
const CultureReport = lazy(() =>
  retry(() => import('pages/Reports/CultureReport/CultureReport')),
);
const IncidentsReport = lazy(() =>
  retry(() => import('pages/Reports/Incidents')),
);
const SentimentReport = lazy(() =>
  retry(() => import('pages/Reports/SentimentReport/SentimentReport')),
);
const UsbReport = lazy(() =>
  retry(() => import('pages/Reports/USBReport/USBReport')),
);
const OverviewReport = lazy(() =>
  retry(() => import('pages/Reports/OverviewReport/OverviewReport')),
);
const RefresherTests = lazy(() =>
  retry(() => import('pages/Settings/RefresherTests/RefresherTests')),
);
const OrganisationSettings = lazy(() =>
  retry(
    () => import('pages/Settings/OrganisationSettings/OrganisationSettings'),
  ),
);
const CreateOrganisation = lazy(() =>
  retry(
    () => import('pages/Settings/OrganisationManagement/CreateOrganisation'),
  ),
);
const DelegateToOrganisation = lazy(() =>
  retry(
    () =>
      import('pages/Settings/OrganisationManagement/DelegateToOrganisation'),
  ),
);
const EditOrganisation = lazy(() =>
  retry(() => import('pages/Settings/OrganisationManagement/EditOrganisation')),
);
const ManageGroup = lazy(() =>
  retry(() => import('pages/Settings/GroupManagement/ManageGroup')),
);
const ReportContent = lazy(() =>
  retry(() => import('pages/Reports/content/ReportContent')),
);
const ReportCultureFactors = lazy(() =>
  retry(
    () => import('pages/Reports/CultureFactorsReport/CultureFactorsReport'),
  ),
);
const CultureGroupComparison = lazy(() =>
  retry(
    () =>
      import('pages/Reports/Culture/GroupComparison/CultureGroupComparison'),
  ),
);
const PageNotFound = lazy(() =>
  retry(() => import('pages/PageNotFound/PageNotFound')),
);
const ModuleQuiz = lazy(() =>
  retry(() => import('pages/Quizzes/Module/Module')),
);
const AdviceTopicsOverview = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Topics/Overview/TopicsOverview')),
);
const CreateAdviceTopic = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Topics/Create/CreateTopic')),
);
const EditAdviceTopic = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Topics/Edit/EditTopic')),
);
const AdviceQuestions = lazy(() =>
  retry(
    () => import('../pages/Admin/Advice/Questions/Overview/QuestionsOverview'),
  ),
);
const AdviceSettings = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Settings/Settings')),
);
const CreateAdviceQuestion = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Questions/Create/CreateQuestion')),
);
const EditAdviceQuestion = lazy(() =>
  retry(() => import('../pages/Admin/Advice/Questions/Edit/EditQuestion')),
);
const GoalsAdmin = lazy(() => retry(() => import('pages/Admin/Goals/Goals')));
const GoalsSettings = lazy(() =>
  retry(() => import('pages/Admin/Goals/components/GoalsSettings')),
);
const CreateGoal = lazy(() =>
  retry(() => import('pages/Admin/Goals/Create/CreateGoal')),
);
const EditGoal = lazy(() =>
  retry(() => import('pages/Admin/Goals/Edit/EditGoal')),
);
const BehaviourOverview = lazy(() =>
  retry(() => import('pages/Reports/Behaviours/Overview/BehaviourOverview')),
);
const BehaviourGroupComparison = lazy(() =>
  retry(
    () => import('pages/Reports/Behaviours/GroupComparison/GroupComparison'),
  ),
);
const BehaviourPeopleComparison = lazy(() =>
  retry(
    () => import('pages/Reports/Behaviours/PeopleComparison/PeopleComparison'),
  ),
);
const BehaviourComparison = lazy(() =>
  retry(
    () =>
      import(
        'pages/Reports/Behaviours/BehaviourComparison/BehaviourComparison'
      ),
  ),
);
const Saas = lazy(() =>
  retry(() => import('pages/Reports/SAAS/Overview/Saas')),
);
const BehaviourDetail = lazy(() =>
  retry(
    () => import('pages/Reports/Behaviours/BehaviourDetail/SecurityBehaviour'),
  ),
);
const BehaviourPeopleDetails = lazy(() =>
  retry(() => import('pages/Reports/Behaviours/PeopleDetails/PeopleDetails')),
);
const BehaviourIndex = lazy(() =>
  retry(() => import('pages/Reports/Behaviours/BehaviourIndex/BehaviourIndex')),
);
const BehaviourRisk = lazy(() =>
  retry(() => import('pages/Reports/Behaviours/Risk/Risk')),
);
const Dashboard = lazy(() =>
  retry(() => import('pages/Dashboard/User/Dashboard')),
);
const OrganisationManagement = lazy(() =>
  retry(
    () =>
      import('pages/Settings/OrganisationManagement/OrganisationManagement'),
  ),
);
const Integrations = lazy(() =>
  retry(() => import('pages/Settings/Integrations/Integrations')),
);
const IntegrationDetailsPage = lazy(() =>
  retry(() => import('pages/Settings/Integrations/IntegrationDetailsPage')),
);
const ApiToken = lazy(() =>
  retry(
    () => import('pages/Settings/Integrations/components/ApiToken/ApiToken'),
  ),
);
const SecurityBehaviourSummary = lazy(() =>
  retry(
    () =>
      import(
        'pages/Settings/Integrations/components/SecurityBehaviourSummary/SecurityBehaviourSummary'
      ),
  ),
);
const Phishing = lazy(() =>
  retry(() => import('pages/Settings/Phishing/Phishing')),
);
const CreatePhishingCampaign = lazy(() =>
  retry(() => import('pages/Settings/Phishing/CreatePhishingCampaign')),
);
const EditPhishingCampaign = lazy(() =>
  retry(() => import('pages/Settings/Phishing/EditPhishingCampaign')),
);
const ViewPhishingCampaign = lazy(() =>
  retry(() => import('pages/Settings/Phishing/campaign/ViewPhishingCampaign')),
);
const CreatePhishingEmail = lazy(() =>
  retry(() => import('pages/Settings/PhishingEmails/CreatePhishingEmail')),
);
const EditPhishingEmail = lazy(() =>
  retry(() => import('pages/Settings/PhishingEmails/EditPhishingEmail')),
);
const PhishingEmailPreview = lazy(() =>
  retry(() => import('pages/Settings/PhishingEmails/PhishingEmailPreview')),
);
const PhishingLandingPagesLibrary = lazy(() =>
  retry(() => import('pages/Settings/PhishingLandingPages')),
);
const CreatePhishingLandingPage = lazy(() =>
  retry(
    () =>
      import('pages/Settings/PhishingLandingPages/CreatePhishingLandingPage'),
  ),
);
const EditPhishingLandingPage = lazy(() =>
  retry(
    () => import('pages/Settings/PhishingLandingPages/EditPhishingLandingPage'),
  ),
);

const PhishingInterventionLibrary = lazy(() =>
  retry(() => import('pages/Settings/PhishingIntervention')),
);
const CreateInterventionPage = lazy(() =>
  retry(
    () => import('pages/Settings/PhishingIntervention/CreateInterventionPage'),
  ),
);
const EditInterventionPage = lazy(() =>
  retry(
    () => import('pages/Settings/PhishingIntervention/EditInterventionPage'),
  ),
);

const LandingPagePreview = lazy(() =>
  retry(
    () =>
      import('pages/Settings/PhishingLandingPages/PhishingLandingPagePreview'),
  ),
);

const InterventionPagePreview = lazy(() =>
  retry(
    () => import('pages/Settings/PhishingIntervention/InterventionPagePreview'),
  ),
);
const PhishingReport = lazy(() =>
  retry(() => import('pages/Reports/Phishing/Phishing')),
);
const PhishingActivityLogs = lazy(() =>
  retry(() => import('pages/Reports/Phishing/ActivityLogs/ActivityLogs')),
);
const PhishingPeopleActivity = lazy(() =>
  retry(() => import('pages/Reports/Phishing/PeopleActivity/PeopleActivity')),
);
const PhishingCampaignsComparison = lazy(() =>
  retry(() => import('pages/Reports/Phishing/CampaignsComparison')),
);
const PhishingGroupActivity = lazy(() =>
  retry(() => import('pages/Reports/Phishing/GroupActivity')),
);
const PhishingEmailLibrary = lazy(() =>
  retry(() => import('pages/Settings/PhishingEmails')),
);
const AssistActivityLogs = lazy(() =>
  retry(() => import('pages/Reports/Assist/ActivityLogs/ActivityLogs')),
);
const AssistCardViews = lazy(() =>
  retry(() => import('pages/Reports/Assist/CardViews/CardViews')),
);
const AssistUsers = lazy(() =>
  retry(() => import('pages/Reports/Assist/Users/Users')),
);
const ProtectActivityLogs = lazy(() =>
  retry(() => import('pages/Reports/Protect/ActivityLogs/ActivityLogs')),
);
const GoalResponseReport = lazy(() =>
  retry(() => import('pages/Reports/Protect/GoalResponses/GoalResponses')),
);
const GoalsComparisonViews = lazy(() =>
  retry(() => import('pages/Reports/Goals/GoalComparison/GoalComparison')),
);
const GoalCard = lazy(() => retry(() => import('pages/Goals/GoalCard')));
const CreateAlertCampaign = lazy(() =>
  retry(() => import('pages/Notifications/Create/CreateAlertCampaign')),
);
const CreateNotification = lazy(() =>
  retry(() => import('pages/Notifications/Create/CreateNudgeCampaign')),
);
const EditNudgeCampaign = lazy(() =>
  retry(() => import('pages/Notifications/Edit/EditNudgeCampaign')),
);
const EditAlertCampaign = lazy(() =>
  retry(() => import('pages/Notifications/Edit/EditAlertCampaign')),
);
const NotificationPreferences = lazy(() =>
  retry(() => import('pages/Notifications/NotificationPreferences')),
);
const NotificationInbox = lazy(() =>
  retry(() => import('pages/Notifications/LatestNotifications')),
);
const NudgeCampaignReport = lazy(() =>
  retry(() => import('pages/Notifications/OverviewReport/NudgeCampaignReport')),
);
const AlertCampaignReport = lazy(() =>
  retry(() => import('pages/Notifications/OverviewReport/AlertCampaignReport')),
);

const NudgeCampaignDetailsReport = lazy(() =>
  retry(() => import('pages/Notifications/Details/NudgeCampaignDetailsReport')),
);

const AlertCampaignDetailsReport = lazy(() =>
  retry(() => import('pages/Notifications/Details/AlertCampaignDetailsReport')),
);

const NotificationsLibrary = lazy(() =>
  retry(() => import('pages/Notifications/Templates/Library/NudgeLibrary')),
);

const AlertLibrary = lazy(() =>
  retry(() => import('pages/Notifications/Templates/Library/AlertLibrary')),
);

const NudgeCreateTemplate = lazy(() =>
  retry(
    () => import('pages/Notifications/Templates/Create/NudgeCreateTemplate'),
  ),
);

const AlertCreateTemplate = lazy(() =>
  retry(
    () => import('pages/Notifications/Templates/Create/AlertCreateTemplate'),
  ),
);

const LearningOverview = lazy(() =>
  retry(() => import('pages/Reports/Learning/Overview')),
);
const Knowledge = lazy(() =>
  retry(() => import('pages/Reports/Learning/Knowledge')),
);
const ModuleComparison = lazy(() =>
  retry(() => import('pages/Reports/Learning/ModuleComparison')),
);
const PeopleComparison = lazy(() =>
  retry(() => import('pages/Reports/Learning/PeopleComparison')),
);

const GroupComparison = lazy(() =>
  retry(() => import('pages/Reports/Learning/GroupComparison')),
);
const LearningCampaignReports = lazy(() =>
  retry(() => import('pages/Reports/Learning/Campaigns')),
);
const Reports = lazy(() => retry(() => import('pages/Reports/Reports')));

const Actions = lazy(() => retry(() => import('pages/Actions/Actions')));
const LearningModules = lazy(() =>
  retry(() => import('pages/Dashboard/Learning/Learning')),
);

const PlatformSetupSlack = lazy(() =>
  retry(() => import('pages/Admin/PlatformSetup/SlackSetup')),
);
const People = lazy(() => retry(() => import('pages/Admin/People/People')));

const MyProgressRedirect = () => {
  const userInfo = useAppSelector(selectUserInfo);
  return <Navigate to={`/reports/user/${userInfo.publicId}/${userInfo.id}`} />;
};

const CreateGroup = lazy(() =>
  retry(() => import('pages/Settings/GroupManagement/CreateNewGroup')),
);

const CreateSubGroup = lazy(() =>
  retry(() => import('pages/Settings/GroupManagement/CreateSubGroup')),
);

const BehaviourInterventions = lazy(() => {
  return retry(() => import('pages/Settings/BehaviourInterventions'));
});
const CreateLearningCampaign = lazy(() => {
  return retry(
    () => import('pages/Admin/LearningCampaigns/Create/CreateLearningCampaign'),
  );
});
const EditLearningCampaign = lazy(() => {
  return retry(
    () => import('pages/Admin/LearningCampaigns/Edit/EditLearningCampaign'),
  );
});
const ViewLearningCampaign = lazy(() => {
  return retry(
    () => import('pages/Admin/LearningCampaigns/Details/DetailsView'),
  );
});
const ModuleLibraryOverview = lazy(() => {
  return retry(
    () => import('pages/Admin/ModuleLibrary/Overview/ModuleLibraryOverview'),
  );
});
const CreateCustomModule = lazy(() => {
  return retry(
    () => import('pages/Admin/ModuleLibrary/Create/CreateCustomModule'),
  );
});
const EditCustomModule = lazy(() => {
  return retry(() => import('pages/Admin/ModuleLibrary/Edit/EditCustomModule'));
});
const RiskOverview = lazy(() => {
  return retry(() => import('pages/Reports/Risk/Overview/Overview'));
});

const RiskGroupComparison = lazy(() => {
  return retry(
    () => import('pages/Reports/Risk/GroupComparison/GroupComparison'),
  );
});

const RiskPeopleComparison = lazy(() => {
  return retry(
    () => import('pages/Reports/Risk/PeopleComparison/PeopleComparison'),
  );
});

const RiskComparison = lazy(() => {
  return retry(
    () => import('pages/Reports/Risk/RiskComparison/RiskComparison'),
  );
});

const PlatformSettings = lazy(() => {
  return retry(() => import('pages/Settings/Platform/PlatformSettings'));
});

const LearningCampaignOverview = lazy(() => {
  return retry(
    () =>
      import('pages/Admin/LearningCampaigns/Overview/LearningCampaignOverview'),
  );
});

const WorkflowsBuilder = lazy(() => {
  return retry(() => import('pages/Workflows/WorkflowBuilder'));
});

const WorkflowReport = lazy(() =>
  retry(() => import('pages/Workflows/WorkflowReport')),
);

const WorkflowDetails = lazy(() =>
  retry(() => import('pages/Workflows/WorkflowDetails')),
);

const WorkflowsFromStore = lazy(() =>
  retry(() => import('pages/Workflows/WorkflowsFromStore')),
);

const WorkflowHistory = lazy(() =>
  retry(() => import('pages/Workflows/WorkflowHistoryReport')),
);

const WorkflowLibrary = lazy(() =>
  retry(() => import('pages/Workflows/WorkflowLibrary')),
);

const CustomizeBranding = lazy(() => {
  return retry(
    () =>
      import(
        'pages/Settings/OrganisationSettings/components/CustomizeBranding'
      ),
  );
});

const ScheduleReport = lazy(() => {
  return retry(() => import('pages/Reports/Schedule/ScheduleReport'));
});

const UserCharacteristics = lazy(() => {
  return retry(
    () => import('pages/Settings/UserCharacteristics/UserCharacteristics'),
  );
});

const HumanRiskImpact = lazy(() => {
  return retry(() => import('pages/Reports/Risk/Impact/Impact'));
});

const ReportLibrary = lazy(() => {
  return retry(() => import('pages/Reports/Library/ReportLibrary'));
});

const InsightsReport = lazy(() => {
  return retry(() => import('pages/Reports/Risk/Insights/Insights'));
});

const RiskDetail = lazy(() => {
  return retry(() => import('pages/Reports/Risk/RiskDetail/RiskDetail'));
});

const UserCharacteristicsReport = lazy(() => {
  return retry(
    () => import('pages/Reports/UserCharacteristics/UserCharacteristics'),
  );
});

const IntegrationSummary = lazy(() => {
  return retry(
    () => import('pages/Reports/IntegrationSummary/IntegrationSummary'),
  );
});

const PasswordExposureOverview = lazy(() =>
  retry(() => import('pages/Reports/PasswordExposure/Overview')),
);

const CompromisedUsers = lazy(() =>
  retry(() => import('pages/Reports/PasswordExposure/CompromisedUsers')),
);

const RiskActivityLog = lazy(() => {
  return retry(() => import('pages/Reports/Risk/ActivityLog/ActivityLog'));
});

const RiskUserDetail = lazy(() => {
  return retry(() => import('pages/Reports/Risk/UserDetail/UserDetail'));
});

const RiskFactors = lazy(() => {
  return retry(() => import('pages/Reports/Risk/RiskFactors'));
});

const EventLogReport = lazy(() => {
  return retry(() => import('pages/Reports/EventLog/EventLogReport'));
});

const QuizProofreading = lazy(() => {
  return retry(() => import('pages/Proofreading/QuizProofreading'));
});

const NotificationsProofreading = lazy(() => {
  return retry(() => import('pages/Proofreading/NotificationsProofreading'));
});

const AllReports = lazy(() => {
  return retry(
    () => import('pages/Reports/ReportLibrary/AllReports/AllReports'),
  );
});

const ReportFavourites = lazy(() => {
  return retry(
    () =>
      import('pages/Reports/ReportLibrary/ReportFavourites/ReportFavourites'),
  );
});

const PageGeneratorsPage = lazy(() => {
  return retry(() => import('pages/PageGenerators'));
});

export const withLayoutRoutes: {
  path: string;
  altPaths?: string[];
  component: any;
  title?: string;
  featureFlag?: string;
  features?: Array<string>;
  isVisible?: ({
    settings,
    featureFlagsContext,
  }: {
    settings: SettingsStateInterface;
    featureFlagsContext?: FeatureFlagsInterface | null;
  }) => boolean;
}[] = [
  {
    path: '/',
    component: Dashboard,
    title: 'label_titleDashboard',
  },
  {
    path: '/reports/behaviours',
    altPaths: [
      '/reports/behaviour-iq',
      '/behaviouriq/overview',
      '/reports/behaviour',
    ],
    component: BehaviourOverview,
    title: 'label_titleBehaviourIQOverview',
    features: ['risk.report.basic'],
  },
  {
    path: '/reports/behaviours/group-comparison',
    component: BehaviourGroupComparison,
    title: 'pageTitle.behaviourGroupComparison',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/behaviours/people-comparison',
    component: BehaviourPeopleComparison,
    title: 'pageTitle.behaviourPeopleComparison',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/behaviours/comparison',
    component: BehaviourComparison,
    title: 'pageTitle.behaviourComparison',
    features: ['risk.report.basic'],
  },
  {
    path: '/reports/behaviours/behaviour/:pageId',
    altPaths: ['/reports/behaviour-iq/behaviour/:pageId'],
    component: BehaviourDetail,
    title: 'label_titleBehaviourIQSecurityBehaviour',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/behaviours/:behaviourType/:pageId/people',
    altPaths: ['/reports/behaviour-iq/people/:behaviourType/:pageId'],
    component: BehaviourPeopleDetails,
    title: 'label_titleBehaviourIQPeople',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/behaviours/index',
    altPaths: ['/reports/behaviour-iq/index'],
    component: BehaviourIndex,
    title: 'label_titleBehaviourIQIndex',
  },
  {
    path: '/settings/phishing',
    component: Phishing,
    title: 'label_titlePhishingSettingsCampaigns',
  },
  {
    path: '/settings/phishing/create-campaign',
    component: CreatePhishingCampaign,
    title: 'label_titlePhishingSettings',
  },
  {
    path: '/settings/phishing/edit-campaign/:campaignId',
    component: EditPhishingCampaign,
    title: 'label_titlePhishingSettings',
  },
  {
    path: '/settings/phishing/view-campaign/:campaignId',
    component: ViewPhishingCampaign,
    title: 'label_titlePhishingSettings',
  },
  {
    path: '/settings/phishing-emails',
    component: PhishingEmailLibrary,
    title: 'label_titlePhishingSettingsEmailLibrary',
  },
  {
    path: '/settings/phishing-emails/create-email',
    component: CreatePhishingEmail,
    title: 'label_titlePhishingSettings',
  },
  {
    path: '/settings/phishing-emails/edit-email/:emailId',
    component: EditPhishingEmail,
    title: 'label_titlePhishingSettings',
  },
  {
    path: '/settings/behaviour-interventions',
    component: BehaviourInterventions,
    title: 'pageTitle.behaviourInterventions',
  },
  {
    path: '/phishing-email-preview/:emailId',
    component: PhishingEmailPreview,
    title: 'pageTitle.phishingEmailsPreview',
  },
  {
    path: '/settings/phishing-landing-pages',
    component: PhishingLandingPagesLibrary,
    title: 'label_titlePhishingLandingPages',
  },
  {
    path: '/settings/phishing-landing-pages/create',
    component: CreatePhishingLandingPage,
    title: 'label_titlePhishingLandingPagesCreate',
  },
  {
    path: '/settings/phishing-landing-pages/edit/:landingPageId',
    component: EditPhishingLandingPage,
    title: 'label_titlePhishingLandingPagesEdit',
  },
  {
    path: '/settings/phishing-intervention-pages',
    component: PhishingInterventionLibrary,
    title: 'label_titlePhishingInterventionPages',
  },
  {
    path: '/settings/phishing-intervention-pages/create',
    component: CreateInterventionPage,
    title: 'pageTitle.interventionPageCreate',
  },
  {
    path: 'settings/phishing-intervention-pages/edit/:interventionPageId',
    component: EditInterventionPage,
    title: 'pageTitle.interventionPageEdit',
  },
  {
    path: '/landing-page-preview/:landingPageId',
    component: LandingPagePreview,
    title: 'pageTitle.landingPagePreview',
  },
  {
    path: '/intervention-page-preview/:pageId',
    component: InterventionPagePreview,
    title: 'pageTitle.landingPagePreview',
  },
  {
    path: '/awareness/msp-toolkit',
    component: MSPToolkit,
    title: 'pageTitle.MSPToolkit',
  },
  {
    path: '/awareness/toolkit',
    component: AwarenessTools,
    title: 'label_titleAwarenessToolkit',
  },
  {
    path: '/refresher-test',
    component: RefresherTest,
    title: 'label_titleRefresherTest',
  },
  {
    path: '/reports/behaviour/proactivity',
    altPaths: ['/reports/proactivity'],
    component: Proactivity,
    title: 'label_titleProactivity',
  },
  {
    path: '/feedback-questions',
    component: FeedBackQuestions,
    title: 'label_titleFeedbackQuestions',
  },
  {
    path: '/learning/advice',
    component: OnDemandHelp,
    title: 'pageTitle.advice',
    altPaths: ['/learning/assist'],
  },
  {
    path: '/learning/advice/:assistType',
    component: OnDemandHelpTopics,
    title: 'pageTitle.advice',
    altPaths: ['/learning/assist/:assistType'],
    features: ['on_demand_help'],
  },
  {
    path: '/learning/advice/:assistType/:adviceId',
    component: OnDemandHelpAdvice,
    title: 'pageTitle.advice',
    altPaths: ['/learning/assist/:assistType/:adviceId'],
    features: ['on_demand_help'],
  },
  {
    path: '/learning/assist',
    component: OnDemandHelp,
    title: 'label_titleAssist',
  },
  {
    path: '/learning/assist/:assistType',
    component: OnDemandHelpTopics,
    title: 'label_titleAssistQuestions',
    features: ['on_demand_help'],
  },
  {
    path: '/learning/assist/:assistType/:adviceId',
    component: OnDemandHelpAdvice,
    title: 'label_titleAssist',
    features: ['on_demand_help'],
  },
  {
    path: '/settings/featured-modules',
    component: FeaturedModules,
    title: 'label_titleFeaturedModules',
  },
  {
    path: '/settings/culture',
    component: CultureSettings,
    title: 'label_titleCultureIQSettings',
    altPaths: ['/settings/cultureiq'],
  },
  {
    path: '/settings/upload-manager',
    component: CSVUploadManager,
    title: 'label_titleUploadManager',
  },
  {
    path: '/settings/upload-manager-v2',
    component: CSVUploadManagerV2,
    title: 'label_titleUploadManager',
    featureFlag: 'use-react-spreadsheet-importer',
  },
  {
    path: '/settings/learning',
    component: Learning,
    title: 'label_titleLearningSettings',
  },
  {
    path: '/settings/edit-profile',
    component: EditProfile,
    title: 'label_titleEditProfile',
  },
  {
    path: '/settings/edit-profile/:userId',
    component: EditProfile,
    title: 'label_titleEditProfile',
  },
  {
    path: '/settings/risk-rooms',
    component: RiskRoomsSettings,
    title: 'label_titleRiskRoomsSettings',
  },
  {
    path: '/settings/add-new-user',
    component: AddNewUser,
    title: 'label_titleAddNewUser',
  },
  {
    path: '/settings/refresher-tests',
    component: RefresherTests,
    title: 'label_titleRefresherTestSettings',
  },
  {
    path: '/settings/email-activity',
    component: EmailActivity,
    title: 'label_titleEmailActivity',
  },
  {
    path: '/settings/audit-activity',
    component: AuditActivity,
    title: 'label_titleAuditActivity',
  },
  {
    path: '/settings/account-overview',
    component: AccountOverview,
    title: 'label_titleAccountOverview',
  },
  {
    path: '/settings/phishing-activity',
    component: PhishingActivity,
    title: 'label_titlePhishingActivity',
  },
  {
    path: '/settings/identity-management',
    component: IdentityManagement,
    title: 'label_titleIdentityManagement',
  },
  {
    path: '/settings/identity-management/login',
    component: IdentityManagementV2,
    featureFlag: 'use-improved-identity-management',
    title: 'label_titleIdentityManagement',
  },
  {
    path: '/settings/identity-management/login/connection/create/:provider',
    component: IdentityManagementCreateForm,
    featureFlag: 'use-improved-identity-management',
    title: 'label_titleIdentityManagement',
    features: ['edit.sso.details'],
  },
  {
    path: '/settings/identity-management/login/connection/edit/:id',
    component: IdentityManagementEditForm,
    featureFlag: 'use-improved-identity-management',
    title: 'label_titleIdentityManagement',
  },
  {
    path: '/settings/identity-management/user-provisioning',
    component: UserProvisioning,
    featureFlag: 'use-improved-identity-management',
    title: 'label_titleIdentityManagement',
  },
  {
    path: '/settings/integrations',
    component: Integrations,
    title: 'pageTitle.integrations',
  },
  {
    path: '/settings/integrations/:integrationId',
    component: IntegrationDetailsPage,
    title: 'pageTitle.integrations',
  },
  {
    path: '/settings/integrations/api-token',
    component: ApiToken,
    title: 'page.integrations.api.token.title',
  },
  {
    path: '/settings/integrations/security-behaviours-summary',
    component: SecurityBehaviourSummary,
    featureFlag: 'use-security-behaviour-summary',
    title: 'page.reports.engagements.activities.security.behaviours.header',
  },
  {
    path: '/settings/group-management',
    component: GroupManagement,
    title: 'label_titleGroupManagement',
  },
  {
    path: '/settings/group-management/:groupId',
    component: ManageGroup,
    title: 'label_titleGroupManagement',
  },
  {
    path: '/settings/organisation-management',
    component: OrganisationManagement,
    title: 'label_titleOrganisationManagement',
  },
  {
    path: '/settings/organisation',
    component: OrganisationSettings,
    title: 'label_titleOrganisationSettings',
  },
  {
    path: `/settings/organisation/customize-branding`,
    component: CustomizeBranding,
    title: 'page.title.customizeBranding',
  },
  {
    path: '/settings/organisation-management/create',
    component: CreateOrganisation,
    title: 'label_titleCreateOrganisation',
  },
  {
    path: '/settings/organisation-management/:clientId',
    component: EditOrganisation,
    title: 'label_titleEditOrganisation',
  },
  {
    path: '/settings/organisation-management/delegate/:clientId',
    component: DelegateToOrganisation,
    title: 'label_titleOrganisationSettings',
  },
  {
    path: '/culture-sentiment',
    component: TemporarySentimentQuestion,
    title: 'label_titleQuiz',
  },
  {
    path: '/reports/content/:contentType',
    component: ReportContent,
    features: ['culture.report.security_culture'],
  },
  {
    path: '/reports',
    altPaths: ['/reports/overview', '/admin/landing'],
    component: OverviewReport,
    title: 'label_titleReportsOverview',
  },
  {
    path: '/reports/admin-dashboard',
    component: Reports,
    title: 'pageTitle.reports',
  },
  {
    path: '/reports/usb',
    component: UsbReport,
    title: 'label_titleReportsUSB',
    featureFlag: 'use-phishing-usb-simulations',
  },
  {
    path: '/reports/usb/:usbId',
    component: UsbReport,
    title: 'label_titleReportsUSB',
    featureFlag: 'use-phishing-usb-simulations',
  },
  {
    path: '/reports/sentiment',
    component: SentimentReport,
    title: 'label_titleReportsSentiment',
  },
  {
    path: '/reports/culture',
    component: CultureReport,
    title: 'label_titleReportsCulture',
  },
  {
    path: '/reports/culture/:factor',
    component: ReportCultureFactors,
    title: 'label_titleReportsCulture',
  },
  {
    path: '/reports/culture/group-comparison',
    component: CultureGroupComparison,
    title: 'label_titleReportsCulture',
  },
  {
    path: '/reports/incidents',
    component: IncidentsReport,
    title: 'pageTitle.incidents',
    featureFlag: 'use-incidents',
  },
  {
    path: '/reports/user/:publicUserId/:userId/certificates',
    component: Certificates,
    title: 'page.certificates.header.general',
  },
  {
    path: '/reports/user/:publicUserId',
    component: UserReport,
    title: 'label_titleProgress',
  },
  {
    path: '/reports/user/:publicUserId/:userId/*',
    component: UserReport,
    title: 'label_titleProgress',
  },
  {
    path: '/reports/phishing',
    altPaths: ['/reports/behaviour/phishing'],
    component: PhishingReport,
    title: 'label_titleReportsPhishing',
  },
  {
    path: '/reports/phishing/campaign-comparison',
    altPaths: ['/reports/behaviour/phishing-campaigns-comparison'],
    component: PhishingCampaignsComparison,
    title: 'pageTitle.phishingCampaignsComparison',
  },
  {
    path: '/reports/phishing/group-comparison',
    altPaths: ['/reports/behaviour/phishing-groups-activity'],
    component: PhishingGroupActivity,
    title: 'pageTitle.phishingGroupsComparison',
  },
  {
    path: '/reports/phishing/activity-log',
    altPaths: ['/reports/behaviour/phishing-activity-logs'],
    component: PhishingActivityLogs,
    title: 'label_titleReportsPhishingActivityLogs',
  },
  {
    path: '/reports/phishing/people-comparison',
    altPaths: ['/reports/behaviour/phishing-people-activity'],
    component: PhishingPeopleActivity,
    title: 'pageTitle.phishingPeopleActivity',
  },
  {
    path: '/reports/assist/activity-logs',
    component: AssistActivityLogs,
    title: 'pageTitle.assistActivityLogs',
  },
  {
    path: '/reports/assist/card-views',
    component: AssistCardViews,
    title: 'pageTitle.assistCardViews',
  },
  {
    path: '/reports/assist/users',
    component: AssistUsers,
    title: 'pageTitle.assistUserReport',
  },
  {
    path: '/reports/protect/activity-logs',
    component: ProtectActivityLogs,
    title: 'pageTitle.protectActivityLogs',
  },
  {
    path: '/reports/protect/goal-responses',
    altPaths: ['/reports/goals/goal-responses'],
    component: GoalResponseReport,
    title: 'pageTitle.GoalResponses',
  },
  {
    path: '/reports/protect/card-views',
    altPaths: ['/reports/goals/goals-comparison'],
    component: GoalsComparisonViews,
    title: 'pageTitle.goalsCardViews',
  },
  {
    path: '/reports/behaviour-change',
    altPaths: ['/reports/behaviour/change'],
    component: BehaviourChange,
    title: 'label_titleReportsChange',
  },
  {
    path: '/reports/behaviour/passphrase',
    altPaths: ['/reports/passphrases'],
    component: PassphraseReport,
    title: 'label_titleReportsPassphrase',
  },
  {
    path: '/reports/behaviour/learning-activity',
    altPaths: ['/reports/engagement'],
    component: EngagementReport,
    title: 'label_titleReportsLearningActivity',
  },
  {
    path: '/reports/engagement/activities',
    component: EngagementActivities,
    title: 'menu.engagementActivities',
  },
  {
    path: '/reports/engagement/activities/:activityId',
    component: ActivityAttendanceReport,
    title: 'pageTitle.activity.attendance',
    features: ['risk.report.basic'],
  },
  {
    path: `/reports/engagement/activities/create`,
    component: CreateActivity,
    title: 'pageTitle.activity.create',
  },
  {
    path: '/reports/behaviour/advocacy',
    altPaths: ['/reports/advocacy'],
    component: AdvocacyReport,
    title: 'label_titleReportsAdvocacy',
  },
  {
    path: '/reports/security-heroes',
    component: SecurityHeroes,
    title: 'menu.securityHeroes',
  },
  {
    path: '/reports/security-heroes/:userId',
    component: SecurityHeroesUserBreakdown,
    title: 'menu.securityHeroes',
    features: ['security_heroes.report'],
  },
  {
    path: '/reports/saas-usage',
    component: Saas,
    title: 'pageTitle.saas',
  },
  {
    path: '/quiz/:module',
    component: ModuleQuiz,
    title: 'label_titleQuiz',
  },
  {
    path: '/quiz/:module/:questionIndex/*',
    component: ModuleQuiz,
    title: 'label_titleQuiz',
  },
  {
    path: '/admin/usb-download',
    component: UsbPageDownload,
    title: 'label_titleUSBDownload',
    featureFlag: 'use-phishing-usb-simulations',
  },
  {
    path: '/admin/annotation/',
    component: AnnotationsOverview,
    title: 'label_titleAnnotations',
  },
  {
    path: '/admin/annotation/:blockId',
    component: Annotations,
    title: 'label_titleAnnotations',
    features: ['learning.annotations'],
  },
  {
    path: '/admin/annotation/:blockId/:annotationId',
    component: Annotations,
    title: 'label_titleAnnotations',
    features: ['learning.annotations'],
  },
  {
    path: '/admin/csv-report',
    component: CSVReport,
    title: 'label_titleCSVReport',
  },
  {
    path: '/workflows/builder',
    component: WorkflowsBuilder,
    title: 'pageTitle.workflowBuilder',
    features: ['workflows.core'],
  },
  {
    path: '/workflows',
    component: WorkflowReport,
    title: 'pageTitle.workflowsReport',
    features: ['workflows.core'],
  },
  {
    path: 'workflows/activity/:workflowId',
    component: WorkflowHistory,
    title: 'pageTitle.workflowHistoryReport',
    features: ['workflow.core'],
  },
  {
    path: 'workflows/builder/store/:id',
    component: WorkflowsFromStore,
    title: 'pageTitle.workflowBuilder',
    features: ['workflow.core'],
  },
  {
    path: '/workflows/library',
    component: WorkflowLibrary,
    title: 'pageTitle.workflowLibrary',
  },
  {
    path: '/workflows/builder/:id',
    component: WorkflowDetails,
    features: ['workflow.core'],
  },
  {
    path: '/workflows/builder/edit/:id',
    component: WorkflowDetails,
    features: ['workflow.core'],
  },
  {
    path: '/workflows/builder/duplicate/:id',
    component: WorkflowDetails,
    features: ['workflow.core'],
  },
  {
    path: '/news',
    component: News,
    title: 'label_titleNews',
  },
  {
    path: '/my-progress',
    component: MyProgressRedirect,
  },
  {
    path: '/progress',
    component: MyProgressRedirect,
  },
  {
    path: '/admin/user-insights',
    component: InsightsAdmin,
    title: 'label_titleUserInsights',
  },
  {
    path: '/admin/advice/topics',
    component: AdviceTopicsOverview,
    title: 'page.admin.advice.topics',
    altPaths: ['/admin/assist/help-categories', '/admin/advice/categories'],
  },
  {
    path: '/admin/advice/questions',
    component: AdviceQuestions,
    title: 'page.admin.advice.questions',
    altPaths: ['/admin/assist/help-questions'],
  },
  {
    path: '/admin/advice/settings',
    component: AdviceSettings,
    title: 'label_titleAssistSetup',
    altPaths: ['/admin/assist/help-settings'],
  },
  {
    path: '/admin/advice/questions/create',
    component: CreateAdviceQuestion,
    title: 'page.admin.advice.questions.create',
    altPaths: ['/admin/assist/question'],
  },
  {
    path: '/admin/advice/questions/:questionId',
    component: EditAdviceQuestion,
    title: 'page.admin.advice.questions.edit',
    altPaths: ['/admin/assist/question/:questionId'],
  },
  {
    path: '/admin/advice/topics/create',
    component: CreateAdviceTopic,
    title: 'page.admin.advice.topics.create',
    altPaths: ['/admin/assist/category', '/admin/advice/categories/create'],
  },
  {
    path: '/admin/advice/topics/:topicId',
    component: EditAdviceTopic,
    title: 'page.admin.advice.topics.edit',
    altPaths: [
      '/admin/assist/category/:categoryId',
      '/admin/advice/categories/:categoryId',
    ],
  },
  {
    path: '/admin/goals',
    component: GoalsAdmin,
    title: 'pageTitle.goalsAdmin',
  },
  {
    path: '/admin/goals/settings',
    component: GoalsSettings,
    title: 'pageTitle.goalsAdmin',
  },
  {
    path: '/admin/goals/create',
    component: CreateGoal,
    title: 'pageTitle.createGoal',
  },
  {
    path: '/admin/goals/edit/:goalId',
    component: EditGoal,
    title: 'pageTitle.editGoal',
  },
  {
    path: '/goals',
    component: Goals,
    title: 'pageTitle.goals',
  },
  {
    path: '/goals/:goalId',
    component: GoalCard,
    title: 'pageTitle.goals',
  },
  {
    path: '/alerts/create',
    component: CreateAlertCampaign,
    title: 'pageTitle.createAlertCampaign',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/alerts/edit/:id',
    component: EditAlertCampaign,
    title: 'pageTitle.editAlertCampaign',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/nudges/create',
    altPaths: ['/notifications/create'],
    component: CreateNotification,
    title: 'pageTitle.createNotification',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/nudges/edit/:id',
    component: EditNudgeCampaign,
    title: 'pageTitle.editNudgeCampaign',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/notifications/preferences',
    component: NotificationPreferences,
    title: 'pageTitle.notificationPreferences',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/notification/inbox',
    component: NotificationInbox,
    title: 'pageTitle.notificationInbox',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/nudges/library',
    altPaths: ['/notifications/library'],
    component: NotificationsLibrary,
    title: 'pageTitle.nudgeLibrary',
  },
  {
    path: '/alerts/library',
    component: AlertLibrary,
    title: 'pageTitle.alertLibrary',
  },
  {
    path: '/nudges/library/create',
    altPaths: ['/notifications/library/create'],
    component: NudgeCreateTemplate,
    title: 'pageTitle.nudgeLibraryCreate',
    features: ['nudges_alerts.core'],
  },
  {
    path: '/nudges/library/edit/:id',
    altPaths: ['/notifications/library/edit/:id'],
    component: NudgeCreateTemplate,
    title: 'pageTitle.nudgeLibraryCreate',
    features: ['nudges_alerts.core'],
  },
  {
    path: '/alerts/library/create',
    component: AlertCreateTemplate,
    title: 'pageTitle.alertLibraryCreate',
    features: ['nudges_alerts.core'],
  },
  {
    path: '/alerts/library/edit/:id',
    component: AlertCreateTemplate,
    title: 'pageTitle.alertLibraryCreate',
    features: ['nudges_alerts.core'],
  },
  {
    path: '/nudges',
    altPaths: ['/notifications'],
    component: NudgeCampaignReport,
    title: 'pageTitle.notificationReport',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
    features: ['nudges_alerts.core'],
  },
  {
    path: '/alerts',
    component: AlertCampaignReport,
    title: 'page.alertsCampaignReport.title',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
  },
  {
    path: '/nudges/:id',
    altPaths: ['/notifications/:id'],
    component: NudgeCampaignDetailsReport,
    title: 'pageTitle.notificationReport',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
    features: ['nudges_alerts.core'],
  },
  {
    path: '/alerts/:id',
    component: AlertCampaignDetailsReport,
    title: 'page.alertsCampaignReport.title',
    isVisible: ({ settings }) => settings.enableNotifications ?? false,
    features: ['nudges_alerts.core'],
  },
  {
    path: '/reports/learning',
    component: LearningOverview,
    title: 'pageTitle.learningOverview',
    altPaths: ['/reports/learning/overview'],
  },
  {
    path: '/reports/learning/knowledge',
    component: Knowledge,
    title: 'pageTitle.knowledge',
  },
  {
    path: '/reports/learning/module-comparison',
    component: ModuleComparison,
    title: 'pageTitle.moduleComparison',
  },
  {
    path: '/reports/learning/people-comparison',
    component: PeopleComparison,
    title: 'pageTitle.learningPeopleComparison',
  },
  {
    path: '/reports/learning/group-comparison',
    component: GroupComparison,
    title: 'pageTitle.groupComparison',
  },
  {
    path: '/reports/learning/campaigns',
    component: LearningCampaignReports,
    title: 'pageTitle.learningCampaignReport',
  },
  {
    path: '/actions',
    component: Actions,
    title: 'pageTitle.actions',
  },
  {
    path: '/learning',
    component: LearningModules,
    title: 'pageTitle.learning',
  },
  {
    path: 'platform-setup/slack',
    component: PlatformSetupSlack,
    title: 'pageTitle.platformSetupSlack',
  },
  {
    path: '/admin/people',
    component: People,
    title: 'pageTitle.peopleAdmin',
    altPaths: ['/reports/users'],
  },
  {
    path: '/settings/group-management/create',
    component: CreateGroup,
    title: 'label_titleCreateGroup',
  },
  {
    path: '/settings/group-management/:groupId/subgroups/create',
    component: CreateSubGroup,
    title: 'label_titleCreateSubgroup',
  },
  {
    path: '/settings/group-management/:groupId/add/existing/groups',
    component: AddExistingGroups,
    title: 'pageTitle.addExistingGroups',
  },
  {
    path: '/admin/learning-campaigns',
    component: LearningCampaignOverview,
    title: 'pageTitle.learningCampaignOverview',
  },
  {
    path: '/admin/learning-campaigns/create',
    component: CreateLearningCampaign,
    title: 'pageTitle.createLearningCampaign',
    features: [
      'learning.training_modules',
      'learning.story_style_modules',
      'learning.role_specific_modules',
    ],
  },
  {
    path: '/admin/learning-campaigns/edit/:campaignId',
    component: EditLearningCampaign,
    title: 'pageTitle.editLearningCampaign',
    features: [
      'learning.training_modules',
      'learning.story_style_modules',
      'learning.role_specific_modules',
    ],
  },
  {
    path: '/admin/learning-campaigns/view/:campaignId',
    component: ViewLearningCampaign,
    title: 'pageTitle.viewLearningCampaign',
    features: [
      'learning.training_modules',
      'learning.story_style_modules',
      'learning.role_specific_modules',
    ],
  },
  {
    path: '/admin/module-library',
    component: ModuleLibraryOverview,
    title: 'pageTitle.moduleLibrary',
    features: [
      'learning.training_modules',
      'learning.story_style_modules',
      'learning.role_specific_modules',
    ],
  },
  {
    path: '/admin/module-library/create',
    altPaths: ['/admin/custom-modules/create'],
    component: CreateCustomModule,
    title: 'pageTitle.createCustomModule',
    features: ['module_builder'],
  },
  {
    path: '/admin/module-library/edit/:moduleId',
    component: EditCustomModule,
    title: 'pageTitle.editCustomModule',
    features: ['module_builder'],
  },
  {
    path: '/reports/risks',
    component: RiskOverview,
    title: 'pageTitle.riskOverview',
    features: ['risk.report.basic'],
    featureFlag: 'use-split-risks',
    isVisible: ({ featureFlagsContext }) =>
      featureFlagsContext?.['use-split-risks']?.value ?? false,
  },
  {
    path: '/reports/risks/group-comparison',
    component: RiskGroupComparison,
    title: 'pageTitle.behaviourGroupComparison',
    featureFlag: 'use-split-risks',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/risks/people-comparison',
    component: RiskPeopleComparison,
    title: 'pageTitle.behaviourPeopleComparison',
    featureFlag: 'use-split-risks',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/risks/comparison',
    component: RiskComparison,
    title: 'pageTitle.riskComparison',
    featureFlag: 'use-split-risks',
  },
  {
    path: '/settings/platform',
    component: PlatformSettings,
    title: 'pageTitle.platformSettings',
  },
  {
    path: '/reports/schedule',
    component: ScheduleReport,
    title: 'pageTitle.scheduleReport',
    featureFlag: 'use-generate-report-flow',
  },
  {
    path: '/settings/user-characteristics',
    component: UserCharacteristics,
    title: 'pageTitle.platformSettings',
  },
  {
    path: '/reports/risks/impact',
    component: HumanRiskImpact,
    title: 'page.humanRiskManagement.impact.title',
    altPaths: ['/reports/impact'],
    features: ['risk.report.basic'],
  },
  {
    path: '/reports/library',
    component: ReportLibrary,
    title: 'page.reports.reportLibrary.title',
    featureFlag: 'use-generate-report-flow',
  },
  {
    path: '/reports/all-reports',
    component: AllReports,
    title: 'pageTitle.allReports',
  },
  {
    path: '/reports/favourites',
    component: ReportFavourites,
    title: 'pageTitle.reportFavourites',
  },
  {
    path: '/reports/risks/recommendations',
    component: InsightsReport,
    title: 'pageTitle.insightsReport',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/user-information',
    component: UserCharacteristicsReport,
    title: 'pageTitle.userInformationReport',
  },
  {
    path: '/reports/integration-summary',
    component: IntegrationSummary,
    title: 'pageTitle.IntegrationSummary',
    featureFlag: 'use-integration-summary',
    features: ['risk.report.basic'],
  },
  {
    path: '/reports/password-exposure/overview',
    component: PasswordExposureOverview,
    title: 'menu.passwordExposure',
  },
  {
    path: '/reports/password-exposure/compromised-users',
    component: CompromisedUsers,
    title: 'menu.passwordExposure',
  },
  {
    path: '/reports/risks/activity-log',
    component: RiskActivityLog,
    title: 'page.reports.risks.activityLog',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/risks/people-comparison/:userId',
    component: RiskUserDetail,
    title: 'page.reports.risks.userRisk',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/risks/:riskType',
    altPaths: ['/reports/behaviours/risk/:riskType'],
    component: RiskDetail,
    title: 'label_titleBehaviourIQRisk',
    features: ['risk.report.basic'],
    featureFlag: 'use-split-risks',
    isVisible: ({ featureFlagsContext }) =>
      featureFlagsContext?.['use-split-risks']?.value ?? false,
  },
  {
    path: '/reports/behaviours/risk/:riskType',
    altPaths: ['/reports/behaviour-iq/risk/:riskType'],
    component: BehaviourRisk,
    title: 'label_titleBehaviourIQRisk',
    features: ['risk.report.advanced'],
  },
  {
    path: '/reports/risks/:riskType/factors/:factorType',
    component: RiskFactors,
    title: 'page.reports.risk.detail.riskFactors',
    features: ['risk.report.advanced'],
    featureFlag: 'use-split-risks',
    isVisible: ({ featureFlagsContext }) =>
      featureFlagsContext?.['use-split-risks']?.value ?? false,
  },
  {
    path: '/reports/event-log',
    component: EventLogReport,
    title: 'page.reports.eventLog.pageTitle',
  },
  {
    path: '/admin/proofreading/quiz',
    component: QuizProofreading,
    title: 'page.reports.proofreading.quiz.pageTitle',
  },
  {
    path: '/admin/proofreading/nudges',
    component: NotificationsProofreading,
    title: 'pageTitle.nudgeLibrary',
  },
  {
    path: '/admin/user-segments/',
    component: UserSegmentsOverview,
    title: 'pageTitle.userSegmentsOverview',
  },
  {
    path: '/admin/user-segments/view/:segmentId',
    component: ViewUserSegment,
    title: 'pageTitle.viewUserSegment',
  },
  {
    path: '/admin/page/*',
    component: PageGeneratorsPage,
    title: undefined,
  },
];

const routesWithoutSideMenu = ['phishing-email-preview', 'admin/builder'];

const RoutesWithLayout = () => {
  const useImprovedDelegatedAccess = useFeatureFlag(
    'use-improved-delegated-access',
  );
  const featureFlagsContext = useContext(FeatureFlagsContext);

  const { pathname } = useLocation();
  const [withSideMenu, setWithSideMenu] = useState(true);
  const settings = useAppSelector((state) => state.userSettings);
  useEffect(() => {
    if (routesWithoutSideMenu.find((route) => pathname.includes(route))) {
      setWithSideMenu(false);
    } else {
      setWithSideMenu(true);
    }
  }, [pathname]);

  return (
    <Layout
      withUserMenu
      withSideMenu={withSideMenu}
      withTopMenu
      useImprovedDelegatedAccess={useImprovedDelegatedAccess}
    >
      <Suspense fallback={<LoaderBlock />}>
        <Routes>
          {withLayoutRoutes
            .map((route) => ({ isVisible: () => true, ...route }))
            .filter((route) =>
              route.isVisible({ settings, featureFlagsContext }),
            )
            .map((route) => (
              <React.Fragment key={route.path}>
                <Route
                  path={route.path}
                  element={
                    <FeatureFlagged featureFlag={route.featureFlag}>
                      <RouteWithContext title={route.title}>
                        {React.createElement(route.component)}
                      </RouteWithContext>
                    </FeatureFlagged>
                  }
                />
                {(route.altPaths || []).map((altPath) => (
                  <Route
                    key={altPath}
                    path={altPath}
                    element={<AltPathNavigate to={route.path} />}
                  />
                ))}
              </React.Fragment>
            ))}
          <Route
            path="/*"
            element={
              <RouteWithContext>
                <PageNotFound />
              </RouteWithContext>
            }
          />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default RoutesWithLayout;
