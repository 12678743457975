import { SideNav, SideNavMenu, Tag } from '@carbon/react';
import styled from 'styled-components';

import {
  DELEGATED_ACCESS_HEADER_HEIGHT_VALUE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_VALUE,
} from '../Header/Header.styles';

export const TopMenu = styled(SideNavMenu)<{ dir: DirectionType }>``;

export const CustomTag = styled(Tag)`
  color: var(--cds-tag-color-cool-gray, #121619) !important;
  border-radius: 2px;
  padding: 0;
`;

export const PoweredByCybSafeContainer = styled.div`
  /* Below removes the onHover css from the cds--side-nav__submenu class so it's not styled like a button */
  pointer-events: none;
`;

export const CybSafeLogo = styled.img`
  height: 20px;
`;

export const StyledSideNav = styled(SideNav)<{
  expanded: boolean;
  $isAdmin: boolean;
  $withSideMenu: boolean;
  $hasDelegatedAccess: boolean;
}>`
  top: ${({ $hasDelegatedAccess }) =>
    $hasDelegatedAccess
      ? `${HEADER_HEIGHT_VALUE + DELEGATED_ACCESS_HEADER_HEIGHT_VALUE}px`
      : HEADER_HEIGHT};
  border-right: 1px solid var(--cds-layer-accent-01);

  ${({ $isAdmin, $withSideMenu }) => {
    if (!$isAdmin || !$withSideMenu) {
      return `@media only screen and (min-width: 1056px) {
          display: none;
        }`;
    }
  }}

  ${({ expanded }) => {
    if (!expanded) {
      return `@media only screen and (max-width: 1056px) {
          display: none;
        }`;
    }
  }}

  & .cds--side-nav__icon > svg {
    color: var(--cds-icon-secondary, #525252);
  }
`;

export const BottomSection = styled.div`
  border-top-width: 1px;
  border-color: var(--cds-layer-accent-01);
  border-style: solid;
`;

export const LoadingTextContainer = styled.div`
  width: 100%;
  .cds--skeleton__text {
    -webkit-margin-after: 0;
    margin-block-end: 0;
  }
`;
